import type { ModerationStatus } from '@/models/enums';

export enum TaskStatus {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED',
}

export interface Task {
  taskId: string;
  itemCount: number;
  namespace: string;
  taskPoolName: string;
  taskStatus: TaskStatus;
  createdAt: string;
  updatedAt: string;
  assignee: string;
  payload: any;
  items?: ItemRaw[];
  note?: string;
  updatedBy?: string;
}

export interface TaskUserGroup {
  id: string;
  name: string;
  description: string;
}

export interface TaskPool {
  id: string;
  name: string;
  description: string;
  type: string;
  taskUserGroup: TaskUserGroup;
  namespace: string;
  escalationPoolName: string;
}

export interface User {
  userEmail: string;
}

export interface StatusCount {
  indexKey: string;
  approvedCount: number;
  rejectedCount: number;
}

export interface Item<N extends string, T> {
  itemId: string;
  namespace: N;
  externalRefId: string;
  createdAt: number;
  moderationHistory?: ModerationHistory[];
  latestModerationHistory?: ModerationHistory;
  payload: T;
  statusCount: StatusCount;
  isDeleted?: boolean;
}

export type ItemRaw = Item<string, string>;

export type ItemDecoded = Item<string, any>;

export type ItemStandardAds<T> = Item<'standard_ads', T>;

export type ItemGeoLogicAds<T> = Item<'geologic', T>;

export interface ModerationHistory {
  moderator?: string;
  moderatorId?: string;
  moderatedAt?: number;
  moderationStatus: ModerationStatus;
  moderationReason?: string;
  moderationNote?: string;
  extra?: string;
  taskId?: string;
  reviewWorkflow?: string;
}

export interface TaskHistory {
  taskId: string;
  namespace: string;
  taskPoolName: string;
  createdAt: string;
  updatedAt: string;
  assignee: string;
  taskStatus: TaskStatus;
  note?: string;
  updatedBy: string;
}

export interface TaskUser {
  userEmail: string;
}

export const decodeItem = (item: ItemRaw): ItemDecoded => {
  const payload = JSON.parse(item.payload);
  return { ...item, payload };
};
