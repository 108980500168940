import { moderationV3DownloadFileRequest, moderationV3Request } from '@/pages/utils/service';
import { objectToParams } from '@/pages/utils/url';
import type {
  ItemRaw,
  Task,
  TaskHistory,
  TaskPool,
  TaskStatus,
  TaskUser,
  TaskUserGroup,
} from '@/models/task';
import type { ModerationStatus, SearchOpType, SearchSortOrder } from '@/models/enums';
import type {
  Dictionary,
  DICTIONARY_NAME,
  DictionaryRecord,
  DictionaryRecordSchema,
  DictionaryRequestRecord,
} from '@/models/dictionary';
import type { AutoReviewRule, TaskPoolRule } from '@/pages/task-pools/task-pool-rules/model';
import type {
  ElementPassRateRequest,
  ElementPassRateResponse,
  PastReviewResultRequest,
  PastReviewResultResponse,
} from '@/pages/services/interface/StandardAdsElementPassRate';
import type { Creative } from '@/models/standardAds/creative';
import type { Batch, BatchItem, ReviewWorkFlow } from '@/models/batch';
import type { User } from '@/models/user';
import type { Brand } from '@/models/standardAds/brand';
import type { AdCategoryRecord } from '@/models/standardAds/adCategory';
import type { Article } from '@/models/content/article';
import type { Channel } from '@/models/content/channel';
import type { ArticleModeration } from '@/models/content/moderation';
import type { ExportRecord } from '@/models/export';
import type { Category } from '@/models/content/category';
import { get } from 'lodash';

interface PagingParamsBase {
  current: number;
  pageSize: number;
}

export interface PagingResponseBase<T> {
  data: T[];
  total: number;
  orderBy?: string;
  filterBy?: string[];
}

interface FetchItemsParamsBase extends PagingParamsBase {
  createdAtBetween?: string;
  moderatedAtBetween?: string;
  moderators?: string[];
}

export interface SearchTasksParams extends PagingParamsBase {
  taskPools?: string;
  taskStatus?: string;
  status?: string[];
  owner?: string;
  createdAtBetween?: string;
  updatedAtBetween?: string;
  includeItemDetails?: boolean;
  orderBy?: string;
  extra?: string;
  extraLike?: string;
  batchIds?: string;
  sortKey?: string;
  reviewWorkflow: string;
  region: string;
}

export interface SearchDictionaryRecordsParams extends PagingParamsBase {
  dictionaryName: DICTIONARY_NAME;
  groupKey?: string;
  includeRecords?: boolean;
}

export interface GetTasksParams {
  taskIds: string[];
  includeItemDetails?: boolean;
}

export interface UpdateTasksParams {
  taskIds: string[];
  assignee?: string | null;
  taskStatus?: TaskStatus;
}

export interface ExportTasksParams {
  taskPoolName: string;
  assignee?: string;
  taskStatus?: string;
  timeRange: string;
}

export interface SearchItemsParams extends FetchItemsParamsBase {
  taskId?: string;
  moderationStatus?: ModerationStatus;
}

export interface GetCommonItemsParams extends FetchItemsParamsBase {
  itemIds: string[];
  moderationStatus?: ModerationStatus;
  createdAtBetween?: string;
  moderatedAtBetween?: string;
}

export interface ModerateItemsParams {
  itemIds?: string[];
  moderationStatus: ModerationStatus;
  moderationReason?: string;
  moderationNote?: string;
  extra?: string;
  taskIds?: string[];
}

export interface ModerateItemsBatchParams {
  data: ModerationInfo[];
}

export interface ModerateItemsAndTasksBatchParams {
  data: ModerationInfo[];
  assignee?: string | null;
  taskStatus?: TaskStatus;
}

export interface ModerationInfo {
  itemId: string;
  moderationStatus: ModerationStatus;
  moderationReason?: string;
  moderationNote?: string;
  extra?: string;
  taskId?: string;
}

export interface QATaskRequestParams {
  moderationStatus?: ModerationStatus;
  moderatedAtBetween?: string;
  moderationReason?: string;
  moderator?: string;
}

export interface RequestTasksParams {
  assignee: string;
  taskPoolName: string;
  taskNumber: number;
  order?: string;
  sortKey?: string;
  qaParameters?: QATaskRequestParams;
}

export interface SearchCreativesParams {
  opType: SearchOpType;
  filterConditions: FilterCondition[];
  selectedAdvancedParams?: string[];
  sortFields?: SortField[];
}

export interface FilterCondition {
  param: string;
  values: string[];
}

export interface SortField {
  param: string;
  order: SearchSortOrder;
}

export interface RequestTasksResponse {
  taskNumber: number;
}

export interface GetNextTaskResponse {
  id: string;
  type: string;
}

export interface EscalateTasksRequest {
  taskIds: string[];
  note?: string;
}

export interface DeescalateTasksRequest {
  taskIds: string[];
  deescalationAction: string;
  note?: string;
}

export interface CreateTaskPoolRequest {
  name: string;
  description: string;
  type: string;
  taskUserGroupName?: string;
  escalationPoolName?: string;
}

export interface UpdateTaskPoolRequest {
  description: string;
  taskUserGroupName?: string;
  escalationPoolName?: string;
}

export interface CreateTaskUserGroupRequest {
  name: string;
  description: string;
}

export interface UpdateTaskUserGroupRequest {
  description: string;
}

export interface GetTaskUserGroupUsersResponse {
  taskUsers: TaskUser[];
}

export interface CreateDictionaryRecordRequest {
  dictionaryName: string;
  recordSchemaId: number;
  groupKey: string;
  key: string;
  value: any;
}

export interface DeleteDictionaryRecordRequest {
  dictionaryName: string;
  recordId: string;
}

export interface UpdateDictionaryRecordRequest {
  dictionaryName: string;
  recordId: string;
  recordSchemaId?: number;
  groupKey?: string;
  value?: any;
}

export interface BatchUpdateDictionaryRecordRequest {
  dictionaryName: string;
  records: DictionaryRequestRecord[];
}

export interface SaveTaskPoolRequest {
  dictionaryName: string;
  recordSchemaId: number;
  deletedConditionIds: number[];
  rules: TaskPoolRule[];
}

export interface SaveTaskPoolResponse {
  rules: TaskPoolRule[];
}

export interface SaveAutoReviewRuleRequest {
  dictionaryName: string;
  recordSchemaId: number;
  deletedConditionIds: number[];
  rules: AutoReviewRule[];
}

export interface SaveAutoReviewRuleResponse {
  rules: AutoReviewRule[];
}

export type GetUserResponse = {
  name: string;
  email: string;
  roles: string[];
};

export interface SearchCreativesResponse {
  creatives: Creative[];
}

export interface CreateBatchRequest {
  taskPool: string;
  note: string;
  items: BatchItem[];
  reviewWorkflow: ReviewWorkFlow;
}

export interface CreateBatchResponse {
  batch: Batch;
}

export interface SearchBatchesParams extends PagingParamsBase {
  taskPool?: string[];
  region: string;
  reviewWorkflow?: string[];
  createdBy?: string;
  createdAtBetween?: string;
  updatedBy?: string;
  updatedAtBetween?: string;
  orderBy?: string;
  fields?: string[];
  includeTaskDetails?: boolean;
}

export interface SearchBatchesResponse {
  data: Batch[];
  total: number;
}

export interface SearchUsersParams {
  namespace?: string;
  status?: string;
  email?: string;
  disablePagination?: boolean;
}

export interface SearchUsersResponse {
  users: User[];
  total: number;
}

export interface SearchBrandsResponse {
  data: Brand[];
}

export interface GetAdCategoriesResponse {
  data: AdCategoryRecord[];
}

export interface RejectReasonContent {
  target: string;
  policy: string;
  description: string;
  url: string | null;
}

export interface RejectReason {
  code: string;
  content: Map<string, RejectReasonContent>;
  enabled: boolean;
}

export interface RejectReasonResponse {
  data: RejectReason[];
  total: number;
}

export interface SearchArticlesParams {
  current?: number;
  pageSize?: number;
  publishers?: string[];
  channels?: string[];
  categories?: string[];
  reviewResult?: string[];
  ngReason?: string[];
  hasComment?: string;
  keyword?: string;
  searchFor?: string[];
  timeRangeBetween?: string;
  orderBy: string;
  showOnlyUpdatedArticles: boolean;
}

export interface SearchArticlesResponse {
  data: Article[];
  total: number;
}

export interface PatchArticlesRequest {
  data: ArticleModeration[];
}

export interface PatchArticlesResponse {
  data: Article[];
}

export interface GetChannelsResponse {
  data: Channel[];
}

export interface GetCategoriesResponse {
  data: Category[];
}

export interface GetPublishersResponse {
  data: string[];
}

export interface GetExportRecordResponse {
  data: ExportRecord[];
}

export interface CreateExportRecordParams {
  beginTime: number;
  endTime: number;
}

export interface CreateExportRecordResponse {
  data: string;
}

export interface GetPolicyCategoryResponse {
  id: number;
  level: number;
  nameJp: string;
  nameEn: string;
  displayOrder: number;
  type: string;
  meta?: string | null;
  parentId?: number | null;
  children?: GetPolicyCategoryResponse[];
}

export interface GetPolicyCategoriesResponse {
  list: GetPolicyCategoryResponse[];
}

export interface PostPolicyCategoryRequest {
  id?: number | null;
  level: number;
  nameJp: string;
  nameEn: string;
  type: string;
  displayOrder: number;
  meta?: string | null;
  parentId?: number | null | undefined;
  children?: PostPolicyCategoryRequest[];
  ignoreChildren: boolean;
}

export interface PostPolicyCategoriesRequest {
  list: PostPolicyCategoryRequest[];
}

export interface GetBrandWithPolicyCategoryResponse {
  list: BrandWithPolicyCategory[];
}

export interface BrandWithPolicyCategory {
  brandId: number;
  brandName: string;
  policyCategories: PolicyCategoryForBrand[];
}

export interface PolicyCategoryForBrand {
  id: number;
  nameJp: string;
  nameEn: string;
}

export interface UpdateBrandWithPolicyCategoryRequest {
  brandId: number;
  policyIds: number[];
}

export type AddUsersToTaskUserGroupRequest = TaskUser[];

export type RemoveUsersFromTaskUserGroupRequest = TaskUser[];

export type GetTaskPoolsResponse = PagingResponseBase<TaskPool>;

export type GetTaskUserGroupsResponse = PagingResponseBase<TaskUserGroup>;

export type SearchTasksResponse = PagingResponseBase<Task>;

export type GetTasksResponse = PagingResponseBase<Task>;

export type SearchItemsResponse = PagingResponseBase<ItemRaw>;

export type GetCommonItemsResponse = PagingResponseBase<ItemRaw>;

export type GetTaskHistoriesResponse = PagingResponseBase<TaskHistory>;

export type GetDictionaryRecordsResponse = PagingResponseBase<DictionaryRecord>;

export type GetDictionaryRecordSchemasResponse = PagingResponseBase<DictionaryRecordSchema>;

const concatIds = (taskIds: string[]): string => taskIds.map(encodeURIComponent).join(',');

export interface ModerationV3API {
  getTaskPools: () => Promise<GetTaskPoolsResponse>;
  getTaskPoolByName: (taskPoolName: string) => Promise<GetTaskPoolsResponse>;
  searchTasks: (params: SearchTasksParams) => Promise<SearchTasksResponse>;
  getTasks: (params: GetTasksParams) => Promise<GetTasksResponse>;
  updateTasks: (params: UpdateTasksParams) => Promise<void>;
  searchItems: (params: SearchItemsParams) => Promise<SearchItemsResponse>;
  getCommonItems: (params: GetCommonItemsParams) => Promise<GetCommonItemsResponse>;
  /**
   * @deprecated prefer to use moderateItemsBatch instead, which is more flexible
   */
  moderateItems: (params: ModerateItemsParams) => Promise<void>;
  moderateItemsBatch: (params: ModerateItemsBatchParams) => Promise<void>;
  moderateItemsBatchByTaskId: (params: ModerateItemsBatchParams) => Promise<void>;
  moderateItemsAndTasksBatch: (params: ModerateItemsAndTasksBatchParams) => Promise<void>;
  requestTasks: (params: RequestTasksParams) => Promise<RequestTasksResponse>;
  getTaskUserGroupUsers: (taskUserGroupName: string) => Promise<GetTaskUserGroupUsersResponse>;
  exportTaskResult: (params: ExportTasksParams, fileName: string) => Promise<void>;
  getNextTask: (order?: string) => Promise<GetNextTaskResponse>;
  escalateTasks: (params: EscalateTasksRequest) => Promise<void>;
  getTaskHistories: (taskId: string) => Promise<GetTaskHistoriesResponse>;
  deescalateTasks: (params: DeescalateTasksRequest) => Promise<void>;
  getDictionaryRecords: (
    params: SearchDictionaryRecordsParams,
  ) => Promise<GetDictionaryRecordsResponse>;
  updateDictionaryRecord: (params: UpdateDictionaryRecordRequest) => Promise<void>;
  updateDictionaryRecords: (params: {
    dictionaryName: DICTIONARY_NAME.ADS_FOR_SFD_DICTIONARY;
    records: DictionaryRecord[];
  }) => Promise<void>;
  createDictionaryRecord: (params: CreateDictionaryRecordRequest) => Promise<void>;
  deleteDictionaryRecord: (params: DeleteDictionaryRecordRequest) => Promise<void>;
  getDictionaryRecordSchemas: (
    dictionaryName: string,
  ) => Promise<GetDictionaryRecordSchemasResponse>;
  getDictionaryData: (
    namespace: string,
    dictionaryIdOrName: string,
    includeRecords: boolean,
  ) => Promise<Dictionary>;
  saveTaskPoolRules: (
    namespace: string,
    request: SaveTaskPoolRequest,
  ) => Promise<SaveTaskPoolResponse>;
  saveAutoReviewRules: (
    namespace: string,
    request: SaveAutoReviewRuleRequest,
  ) => Promise<SaveAutoReviewRuleResponse>;
  getSmartviewByUrl: (url: string) => Promise<string>;
  calcElementPassRate: (
    namespace: string,
    request: ElementPassRateRequest,
  ) => Promise<ElementPassRateResponse>;
  pastReviewResultHistory: (
    namespace: string,
    fieldName: string,
    request: PastReviewResultRequest,
  ) => Promise<PastReviewResultResponse>;
  pastReviewResultSimilar: (
    namespace: string,
    fieldName: string,
    request: PastReviewResultRequest,
  ) => Promise<PastReviewResultResponse>;
  rejectReasons: (namespace: string, languages: string) => Promise<RejectReasonResponse>;
  searchCreatives: (
    params: SearchCreativesParams,
    current: number,
    pageSize: number,
  ) => Promise<SearchCreativesResponse>;
  csvDownload: (
    params: SearchCreativesParams,
    note: string | undefined,
  ) => Promise<string>;

  createBatch: (params: CreateBatchRequest) => Promise<CreateBatchResponse>;

  searchBatches: (params: SearchBatchesParams) => Promise<SearchBatchesResponse>;

  getAdCategories: () => Promise<GetAdCategoriesResponse>;

  getBrands: () => Promise<SearchBrandsResponse>;

  searchArticles: (params: SearchArticlesParams) => Promise<SearchArticlesResponse>;

  patchArticles: (params: { articles: PatchArticlesRequest }) => Promise<PatchArticlesResponse>;

  getChannels: () => Promise<GetChannelsResponse>;

  getCategories: () => Promise<GetCategoriesResponse>;

  getPublishers: () => Promise<GetPublishersResponse>;

  getExportRecord: (type: string) => Promise<GetExportRecordResponse>;

  createExportRecord: (
    type: string,
    params: CreateExportRecordParams,
  ) => Promise<CreateExportRecordResponse>;

  getPreview: (url: string, edition: string) => Promise<string>;

  getPolicyCategory: (namespace: string, id: number) => Promise<GetPolicyCategoryResponse>;

  getPolicyCategories: (namespace: string) => Promise<GetPolicyCategoriesResponse>;

  postPolicyCategory: (namespace: string, request: PostPolicyCategoryRequest) => Promise<GetPolicyCategoryResponse>;

  deletePolicyCategory: (namespace: string, id: number) => Promise<string>;

  getAdsBrandWithPolicyCategory: (namespace: string, id: number) => Promise<GetBrandWithPolicyCategoryResponse>;

  updateAdsBrandWithPolicyCategory: (namespace: string, brand: UpdateBrandWithPolicyCategoryRequest) => Promise<string>;
}

export const createAPI: (nameSpace: string) => ModerationV3API = (nameSpace: string) => ({
  async getTaskPools(): Promise<GetTaskPoolsResponse> {
    return moderationV3Request(`${nameSpace}/task-pools`);
  },

  async getTaskPoolByName(taskPoolName: string): Promise<GetTaskPoolsResponse> {
    return moderationV3Request(`${nameSpace}/task-pools/${taskPoolName}`);
  },

  async searchTasks(params: SearchTasksParams): Promise<SearchTasksResponse> {
    return moderationV3Request(`${nameSpace}/tasks?${objectToParams(params)}`);
  },

  async getTasks(params: GetTasksParams): Promise<GetTasksResponse> {
    const { taskIds, ...rest } = params;
    return moderationV3Request(`${nameSpace}/tasks/${concatIds(taskIds)}?${objectToParams(rest)}`);
  },

  async updateTasks(params: UpdateTasksParams) {
    const { taskIds, ...rest } = params;
    return moderationV3Request(`${nameSpace}/tasks/${concatIds(taskIds)}`, 'PATCH', rest);
  },

  async searchItems(params: SearchItemsParams): Promise<SearchItemsResponse> {
    return moderationV3Request(`${nameSpace}/items?${objectToParams(params)}`);
  },

  async getCommonItems(params: GetCommonItemsParams): Promise<GetCommonItemsResponse> {
    const { itemIds, ...rest } = params;
    return moderationV3Request(`${nameSpace}/items/${concatIds(itemIds)}?${objectToParams(rest)}`);
  },

  async moderateItems(params: ModerateItemsParams) {
    return moderationV3Request(`${nameSpace}/items`, 'PATCH', params);
  },

  async moderateItemsBatch(params: ModerateItemsBatchParams) {
    return moderationV3Request(`${nameSpace}/items/batch`, 'PATCH', params);
  },

  async moderateItemsBatchByTaskId(params: ModerateItemsBatchParams) {
    return moderationV3Request(
      `${nameSpace}/items/batch/${params.data[0].taskId}`,
      'PATCH',
      params,
    );
  },

  async moderateItemsAndTasksBatch(taskIds: string[], params: ModerateItemsAndTasksBatchParams) {
    return moderationV3Request(`${nameSpace}/items/batch/${concatIds(taskIds)}`, 'PATCH', params);
  },

  async requestTasks(params: RequestTasksParams) {
    return moderationV3Request(`${nameSpace}/tasks/request`, 'POST', params);
  },

  async getTaskUserGroupUsers(taskUserGroupName: string) {
    return moderationV3Request(`${nameSpace}/task-user-groups/${taskUserGroupName}/users`, 'GET');
  },

  async exportTaskResult(params: ExportTasksParams, fileName: string) {
    return moderationV3DownloadFileRequest(
      `${nameSpace}/export-task-result?${objectToParams(params)}`,
      'GET',
      fileName,
    );
  },

  async getNextTask(order: string = 'ASC') {
    return moderationV3Request(`${nameSpace}/tasks/next?order=${order}`);
  },

  async escalateTasks(params: EscalateTasksRequest) {
    const { taskIds, ...rest } = params;
    return moderationV3Request(`${nameSpace}/tasks/escalate/${concatIds(taskIds)}`, 'POST', rest);
  },

  async getTaskHistories(taskId: string) {
    return moderationV3Request(`${nameSpace}/tasks/${taskId}/histories`);
  },

  async deescalateTasks(params: DeescalateTasksRequest) {
    const { taskIds, ...rest } = params;
    return moderationV3Request(`${nameSpace}/tasks/deescalate/${concatIds(taskIds)}`, 'POST', rest);
  },
  async getDictionaryRecords(params: SearchDictionaryRecordsParams) {
    const { dictionaryName, ...rest } = params;
    return moderationV3Request(
      `${nameSpace}/versatile-dictionaries/${dictionaryName}/records?${objectToParams(rest)}`,
      'GET',
    );
  },
  async updateDictionaryRecord(params: UpdateDictionaryRecordRequest) {
    const { dictionaryName, recordId, ...rest } = params;
    return moderationV3Request(
      `${nameSpace}/versatile-dictionaries/${dictionaryName}/records/${recordId}`,
      'PATCH',
      rest,
    );
  },

  async updateDictionaryRecords(params: BatchUpdateDictionaryRecordRequest) {
    const { dictionaryName, records } = params;
    return moderationV3Request(
      `${nameSpace}/versatile-dictionaries/${dictionaryName}/records`,
      'PATCH',
      { records },
    );
  },

  async createDictionaryRecord(params: UpdateDictionaryRecordRequest) {
    const { dictionaryName, ...rest } = params;
    return moderationV3Request(
      `${nameSpace}/versatile-dictionaries/${dictionaryName}/records`,
      'POST',
      rest,
    );
  },
  async deleteDictionaryRecord(params: DeleteDictionaryRecordRequest) {
    return moderationV3Request(
      `${nameSpace}/versatile-dictionaries/${params.dictionaryName}/records/${params.recordId}`,
      'DELETE',
    );
  },
  async getDictionaryRecordSchemas(dictionaryName: string) {
    return moderationV3Request(
      `${nameSpace}/versatile-dictionaries/${dictionaryName}/record-schemas`,
      'GET',
    );
  },
  async getSmartviewByUrl(url: string) {
    return moderationV3Request(`content_us/smartview?url=${encodeURIComponent(url)}`);
  },

  async searchCreatives(
    params: SearchCreativesParams,
    current: number,
    pageSize: number,
  ): Promise<SearchCreativesResponse> {
    return moderationV3Request(
      `${nameSpace}/creatives/search?current=${current}&pageSize=${pageSize}`,
      'POST',
      params,
    );
  },

  async csvDownload(
    params: SearchCreativesParams,
    note: string | undefined,
  ): Promise<string> {
    return moderationV3Request(
      `${nameSpace}/creatives/csv`,
      'POST',
      {
        searchRequest: params,
        note: note
      },
    );
  },

  async createBatch(params: CreateBatchRequest): Promise<CreateBatchResponse> {
    return moderationV3Request(`${nameSpace}/batch`, 'POST', params);
  },

  async searchBatches(params: SearchBatchesParams): Promise<SearchBatchesResponse> {
    return moderationV3Request(`${nameSpace}/batches?${objectToParams(params)}`, 'GET');
  },

  async getAdCategories(): Promise<GetAdCategoriesResponse> {
    return moderationV3Request(`${nameSpace}/adCategories`, 'GET');
  },

  async getBrands(): Promise<SearchBrandsResponse> {
    return moderationV3Request(`${nameSpace}/brands`, 'GET');
  },

  async searchArticles(params: SearchArticlesParams): Promise<SearchArticlesResponse> {
    return moderationV3Request(`${nameSpace}/articles?${objectToParams(params)}`);
  },

  async patchArticles(request: PatchArticlesRequest) {
    return moderationV3Request(`${nameSpace}/articles`, 'PATCH', request);
  },

  async getChannels(): Promise<GetChannelsResponse> {
    return moderationV3Request(`${nameSpace}/channels`, 'GET');
  },

  async getCategories(): Promise<GetChannelsResponse> {
    return moderationV3Request(`${nameSpace}/categories`, 'GET');
  },

  async getPublishers(): Promise<GetPublishersResponse> {
    return moderationV3Request(`${nameSpace}/publishers`, 'GET');
  },

  async getExportRecord(type: string): Promise<GetExportRecordResponse> {
    return moderationV3Request(`${nameSpace}/${type}/export_record`, 'GET');
  },

  async createExportRecord(
    type: string,
    params: CreateExportRecordParams,
  ): Promise<CreateExportRecordResponse> {
    return moderationV3Request(
      `${nameSpace}/${type}/export_record?${objectToParams(params)}`,
      'POST',
    );
  },

  async getPreview(url: string, edition: string): Promise<string> {
    return moderationV3Request(
      `${nameSpace}/preview?url=${encodeURIComponent(url)}&edition=${edition}`,
      'GET',
    );
  },

});

// TODO(MP-989): Migrate all ModerationV3API to use moderationV3APICommon instead
export const standardAdsTask: ModerationV3API = createAPI('standard_ads');
export const standardAdsUSTask: ModerationV3API = createAPI('standard_ads_us');
export const articleModerationTask: ModerationV3API = createAPI('content_us');
export const geologicTask: ModerationV3API = createAPI('geologic');
export const dynamicAdsV3: ModerationV3API = createAPI('dynamic_ads');
export const sampleFeedV3: ModerationV3API = createAPI('dynamic_ads_sample_feed');

export const sfdV3: ModerationV3API = createAPI('ads_for_sfd');
export const sfdContentV3: ModerationV3API = createAPI('sfd_content');

export const moderationV3APICommon = {
  async getCurrentUser(): Promise<GetUserResponse> {
    return moderationV3Request(`user/current`);
  },

  async searchUsers(params: SearchUsersParams): Promise<SearchUsersResponse> {
    return moderationV3Request(`user?${objectToParams(params)}`);
  },

  async getTaskPools(namespace: string): Promise<GetTaskPoolsResponse> {
    return moderationV3Request(`${namespace}/task-pools`);
  },

  async getTaskPoolByName(namespace: string, taskPoolName: string): Promise<GetTaskPoolsResponse> {
    return moderationV3Request(`${namespace}/task-pools/${taskPoolName}`);
  },

  async searchTasks(namespace: string, params: SearchTasksParams): Promise<SearchTasksResponse> {
    return moderationV3Request(`${namespace}/tasks?${objectToParams(params)}`);
  },

  async getTasks(namespace: string, params: GetTasksParams): Promise<GetTasksResponse> {
    const { taskIds, ...rest } = params;
    return moderationV3Request(`${namespace}/tasks/${concatIds(taskIds)}?${objectToParams(rest)}`);
  },

  async updateTasks(namespace: string, params: UpdateTasksParams) {
    const { taskIds, ...rest } = params;
    return moderationV3Request(`${namespace}/tasks/${concatIds(taskIds)}`, 'PATCH', rest);
  },

  async searchItems(namespace: string, params: SearchItemsParams): Promise<SearchItemsResponse> {
    return moderationV3Request(`${namespace}/items?${objectToParams(params)}`);
  },

  async getCommonItems(
    namespace: string,
    params: GetCommonItemsParams,
  ): Promise<GetCommonItemsResponse> {
    const { itemIds, ...rest } = params;
    return moderationV3Request(`${namespace}/items/${concatIds(itemIds)}?${objectToParams(rest)}`);
  },

  async moderateItems(namespace: string, params: ModerateItemsParams) {
    return moderationV3Request(`${namespace}/items`, 'PATCH', params);
  },

  async moderateItemsBatch(namespace: string, params: ModerateItemsBatchParams) {
    return moderationV3Request(`${namespace}/items/batch`, 'PATCH', params);
  },

  async moderateItemsAndTasksBatch(
    namespace: string,
    taskIds: string[],
    params: ModerateItemsAndTasksBatchParams,
  ) {
    return moderationV3Request(`${namespace}/items/batch/${concatIds(taskIds)}`, 'PATCH', params);
  },

  async requestTasks(namespace: string, params: RequestTasksParams) {
    return moderationV3Request(`${namespace}/tasks/request`, 'POST', params);
  },

  async getTaskUserGroupUsers(namespace: string, taskUserGroupName: string) {
    return moderationV3Request(`${namespace}/task-user-groups/${taskUserGroupName}/users`, 'GET');
  },

  async exportTaskResult(namespace: string, params: ExportTasksParams, fileName: string) {
    return moderationV3DownloadFileRequest(
      `${namespace}/export-task-result?${objectToParams(params)}`,
      'GET',
      fileName,
    );
  },

  async getNextTask(namespace: string) {
    return moderationV3Request(`${namespace}/tasks/next`);
  },

  async escalateTasks(namespace: string, params: EscalateTasksRequest) {
    const { taskIds, ...rest } = params;
    return moderationV3Request(`${namespace}/tasks/escalate/${concatIds(taskIds)}`, 'POST', rest);
  },

  async getTaskHistories(namespace: string, taskId: string) {
    return moderationV3Request(`${namespace}/tasks/${taskId}/histories`);
  },

  async deescalateTasks(namespace: string, params: DeescalateTasksRequest) {
    const { taskIds, ...rest } = params;
    return moderationV3Request(`${namespace}/tasks/deescalate/${concatIds(taskIds)}`, 'POST', rest);
  },

  async getDictionaryData(
    namespace: string,
    dictionaryName: string,
    includeRecords: boolean = true,
  ) {
    return moderationV3Request(
      `${namespace}/versatile-dictionaries/${dictionaryName}?includeRecords=${includeRecords}`,
      'GET',
    );
  },

  async standardAdsImport(region: string) {
    return moderationV3Request(`standard_ads/${region}/import`, 'POST');
  },

  async saveTaskPoolRules(
    namespace: string,
    request: SaveTaskPoolRequest,
  ): Promise<SaveTaskPoolResponse> {
    return moderationV3Request(`${namespace}/task-pool-rules`, 'POST', request);
  },

  async saveAutoReviewRules(
    namespace: string,
    request: SaveAutoReviewRuleRequest,
  ): Promise<SaveAutoReviewRuleResponse> {
    return moderationV3Request(`${namespace}/task-pool-auto-review-rules`, 'POST', request);
  },

  async calcElementPassRate(
    namespace: string,
    request: ElementPassRateRequest,
  ): Promise<ElementPassRateResponse> {
    return moderationV3Request(`${namespace}/past-results/pass-rate`, 'POST', request);
  },

  async pastReviewResultHistory(
    namespace: string,
    fieldName: string,
    request: PastReviewResultRequest,
  ): Promise<PastReviewResultResponse> {
    return moderationV3Request(`${namespace}/past-results/histories/${fieldName}`, 'POST', request);
  },

  async pastReviewResultSimilar(
    namespace: string,
    fieldName: string,
    request: PastReviewResultRequest,
  ): Promise<PastReviewResultResponse> {
    return moderationV3Request(`${namespace}/past-results/similar/${fieldName}`, 'POST', request);
  },

  async rejectReasons(namespace: string, region: string, language: string): Promise<RejectReasonResponse> {
    return moderationV3Request(`${namespace}/reject-reasons?region=${region}&languages=${language}`, 'GET');
  },

  async getPolicyCategory(
    namespace: string, 
    policyCategoryId: number, 
    ignoreChildren: boolean
  ): Promise<GetPolicyCategoryResponse> {
    return moderationV3Request(`${namespace}/policy-categories/${policyCategoryId}`, 'GET', { ignoreChildren });
  },

  async getPolicyCategories(
    namespace: string
  ): Promise<GetPolicyCategoriesResponse> {
    return moderationV3Request(`${namespace}/policy-categories`, 'GET');
  },

  async updatePolicyCategory(
    namespace: string, 
    request: PostPolicyCategoryRequest
  ): Promise<GetPolicyCategoryResponse> {
    return moderationV3Request(`${namespace}/policy-categories`, 'POST', request);
  },

  async updatePolicyCategories(
    namespace: string, 
    request: PostPolicyCategoriesRequest
  ): Promise<GetPolicyCategoriesResponse> {
    return moderationV3Request(`${namespace}/policy-categories/bulk`, 'POST', request);
  },

  async deletePolicyCategory(
    namespace: string, 
    policyCategoryId: number,
    ignoreChildren: boolean
  ): Promise<string> {
    return moderationV3Request(`${namespace}/policy-categories/${policyCategoryId}`, 'DELETE', { ignoreChildren });
  },

  async getAllAdBransWithPolicyCategories(
    namespace: string, 
  ): Promise<GetBrandWithPolicyCategoryResponse> {
    return moderationV3Request(`${namespace}/brands/policy-categories`, 'GET');
  },

  async updateAdsBrandWithPolicyCategory(
    namespace: string,
    brand: UpdateBrandWithPolicyCategoryRequest
  ): Promise<string> {
    return moderationV3Request(`${namespace}/brands/policy-categories/${brand.brandId}`, 'POST', brand);
  }
};
